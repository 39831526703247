<template>
  <div class="combo main-cnt">
    <div class="title">套餐列表</div>
    <div class="content">
      <common-table
        ref="comboTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="HotelApi.hotelPackageList"
        :filters="filters"
        :columns="tableColumns"
        @statusChange="statusChange"
        @edit="comboEdit"
        @projectChange="projectChange"
        @hotelChange="hotelChange"
        @issue="comboIssue"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="showAddDialog"
            v-if="authData.indexOf('h_4SShttErG9guavsLxXh1pNBZrCAL') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增套餐</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑套餐弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑套餐' : '新增套餐'"
      width="55%"
      btnWidth="140px"
      top="10vh"
      @wConfirm="confirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="选择项目" prop="village">
          <el-select
            v-model="ruleForm.village"
            clearable
            :disabled="isEdit"
            placeholder="请选择项目"
            @change="dialogProjectChange"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择酒店" prop="hotel">
          <el-select
            v-model="ruleForm.hotel"
            clearable
            :disabled="isEdit"
            placeholder="请选择酒店"
            @change="getRoomTypeData"
          >
            <el-option
              :label="item.h_name"
              :value="item.h_id"
              v-for="item in hotelOptions"
              :key="item.h_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联房型" prop="type">
          <el-select
            v-model="ruleForm.type"
            clearable
            :disabled="isEdit"
            placeholder="请选择关联房型"
          >
            <el-option
              :label="item.hrt_name"
              :value="item.hrt_id"
              v-for="item in roomTypeOptions"
              :key="item.hrt_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="套餐名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入套餐名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐价格" prop="price">
          <el-input
            v-model="ruleForm.price"
            type="number"
            placeholder="请输入套餐价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐类型" prop="comboType">
          <el-select
            v-model="ruleForm.comboType"
            clearable
            :disabled="isEdit"
            placeholder="请选择套餐类型"
          >
            <el-option label="普通套餐" :value="1"></el-option>
            <el-option label="活动套餐" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="销售模式"
          prop="model"
          v-if="ruleForm.comboType == 2"
        >
          <el-select
            v-model="ruleForm.model"
            clearable
            placeholder="请选择销售模式"
          >
            <el-option label="仅活动" :value="1"></el-option>
            <el-option label="可单独" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="预售周期"
          prop="day"
          v-if="ruleForm.comboType == 1"
        >
          <el-input
            v-model="ruleForm.day"
            type="number"
            placeholder="请输入预售周期"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="可用日期"
          prop="date"
          v-if="ruleForm.comboType == 2"
        >
          <el-date-picker
            v-model="ruleForm.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="上架周期"
          prop="cycle"
          v-if="ruleForm.comboType == 2"
        >
          <el-date-picker
            v-model="ruleForm.cycle"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
        <el-divider></el-divider>
        <div class="filter-wp">
          <el-select
            v-model="ruleVal"
            placeholder="请选择规则"
            @change="getRefundRules"
          >
            <el-option label="通用规则" :value="1"></el-option>
            <el-option label="活动规则" :value="2"></el-option>
          </el-select>
        </div>
        <el-form-item class="radios" label="选择退款规则" prop="refundRule">
          <el-radio-group
            v-if="ruleList.length > 0"
            v-model="ruleForm.refundRule"
          >
            <el-radio
              :label="item.rr_id"
              size="large"
              v-for="item in ruleList"
              :key="item.rr_id"
              >{{ item.rr_desc }}</el-radio
            >
          </el-radio-group>
          <div
            v-else
            style="
              text-align: center;
              height: 50px;
              line-height: 50px;
              color: #ccc;
            "
          >
            暂无数据！
          </div>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 发布 弹框 -->
    <w-dialog
      ref="issueDialog"
      class="issue-dialog"
      title="发布上架"
      width="35%"
      btnWidth="140px"
      top="20vh"
      confirmText="确认发布"
      @wConfirm="issueConfirm"
    >
      <div class="name">发布周期</div>
      <el-date-picker
        v-model="issueDate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        value-format="YYYY-MM-DD"
      >
      </el-date-picker>
    </w-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { BasicApi, HotelApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 套餐表格对象 */
const comboTable = ref(null);
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "select",
    name: "pid",
    value: "",
    placeholder: "请选择项目",
    options: [],
    lab: "p_name",
    val: "p_id",
    action: "projectChange",
  },
  {
    filterType: "select",
    name: "hid",
    value: "",
    placeholder: "请选择酒店",
    options: [],
    lab: "h_name",
    val: "h_id",
    action: "hotelChange",
  },
  {
    filterType: "select",
    name: "hrt_id",
    value: "",
    placeholder: "请选择房型",
    options: [],
    lab: "hrt_name",
    val: "hrt_id",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "hp_id",
    label: "套餐ID",
    color: "--text-color",
    minWidth: 60,
  },
  {
    prop: "hotelRoomType",
    objKey: "hrt_name",
    type: "obj",
    label: "所属房型",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "hp_name",
    label: "套餐名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "hp_price",
    label: "默认价格",
    minWidth: 120,
    color: "--theme-color",
  },
  {
    prop: "hp_type_text",
    label: "套餐类型",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "hp_launch_start_time",
    label: "上架日期",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "hp_launch_end_time",
    label: "下架日期",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    type: "switch",
    prop: "hp_status",
    label: "套餐状态",
    minWidth: 120,
    token: "h_E7oRmcwivMCH9r8hrSL9NFz5jNgH",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "h_Fl0mZyMQodFSEHfXvfrrPL2yUR3D",
        className: "theme-font-btn",
      },
      {
        name: "发布上架",
        action: "issue",
        token: "h_Fl0mZyMQodFSEHfXvfrrPL2yU333",
        className: "black-font-btn",
        HiddenKey: "hp_sale_type",
        showValue: [2],
      },
    ],
  },
]);
/** 当前操作行数据 */
const currentRow = ref(null);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    hp_id: row.hp_id,
    hp_status: row.hp_status == 1 ? 2 : 1,
  };
  HotelApi.setHotelPackageStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("套餐状态修改成功！");
      // 重新获取套餐列表数据
      comboTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "套餐状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 表格编辑 */
const comboEdit = (row) => {
  currentRow.value = row;
  isEdit.value = true;
  ruleForm.village = row.hp_pid;
  ruleForm.hotel = row.hp_hid;
  ruleForm.type = row.hp_hrt_id;
  ruleForm.name = row.hp_name;
  ruleForm.price = row.hp_price;
  ruleForm.comboType = row.hp_type;
  ruleForm.day = row.hp_pre_sale_cycle;
  ruleForm.model = row.hp_sale_type;
  ruleForm.date = [
    row.hp_available_start_time ? row.hp_available_start_time : "",
    row.hp_available_end_time ? row.hp_available_end_time : "",
  ];
  ruleForm.cycle = [
    row.hp_launch_start_time ? row.hp_launch_start_time : "",
    row.hp_launch_end_time ? row.hp_launch_end_time : "",
  ];
  ruleForm.refundRule = row.hp_refund_rule ? row.hp_refund_rule : "";
  ruleVal.value = "";
  addDialog.value.show();
  if (row.hp_pid) {
    BasicApi.getHotelByProject({ pid: row.hp_pid }).then((res) => {
      if (res.Code === 200) {
        hotelOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取酒店数据失败！";
        ElMessage.error(msg);
      }
    });
  }
  if (row.hp_hid) {
    BasicApi.getTypeByHotel({ hid: row.hp_hid }).then((res) => {
      if (res.Code === 200) {
        roomTypeOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取户型数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
/** 表格 发布上架 */
const comboIssue = (row) => {
  currentRow.value = row;
  issueDialog.value.show();
};

/** 项目列表 */
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      filters.value[0].options = res.Data ? res.Data : [];
      projectOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 酒店列表数据 */
const hotelOptions = ref([]);
/** 项目选择更改 */
const projectChange = (obj) => {
  filters.value[1].value = "";
  filters.value[1].options = [];
  filters.value[2].value = "";
  filters.value[2].options = [];
  comboTable.value.tableLoad();
  if (obj.value) {
    BasicApi.getHotelByProject({ pid: obj.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取酒店数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
/** 弹框 项目选择更改 */
const dialogProjectChange = (value) => {
  ruleForm.hotel = "";
  hotelOptions.value = [];
  ruleForm.type = "";
  roomTypeOptions.value = [];
  ruleList.value = [];
  if (value) {
    BasicApi.getHotelByProject({ pid: value }).then((res) => {
      if (res.Code === 200) {
        hotelOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取酒店数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};

/** 酒店选择更改 */
const hotelChange = (data) => {
  filters.value[2].options = [];
  filters.value[2].value = "";
  comboTable.value.tableLoad();
  ruleList.value = [];
  if (data && data.value) {
    BasicApi.getTypeByHotel({ hid: data.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[2].options = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取户型数据失败！";
        ElMessage.error(msg);
      }
    });
    if (ruleVal.value) {
      getRefundRules();
    }
  }
};
/** 户型列表 */
const roomTypeOptions = ref([]);
/** 获取户型列表数据 */
const getRoomTypeData = (value) => {
  ruleForm.type = "";
  roomTypeOptions.value = [];
  if (value) {
    BasicApi.getTypeByHotel({ hid: value }).then((res) => {
      if (res.Code === 200) {
        roomTypeOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取户型数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};

/** 新增/编辑弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  village: "", // 项目
  hotel: "", // 酒店
  name: "", // 套餐名称
  type: "", // 关联房型
  price: "", // 套餐价格
  comboType: "", // 套餐类型
  day: "", // 预售周期
  date: "", // 可用日期
  model: "", // 销售模式
  cycle: "", // 上架周期
  refundRule: "", // 退款规则
});
/** 表单规则对象 */
const rules = reactive({
  village: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  hotel: [
    {
      required: true,
      message: "请选择酒店",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入套餐名称",
      trigger: "blur",
    },
  ],
  type: [
    {
      required: true,
      message: "请选择房型",
      trigger: "change",
    },
  ],
  price: [
    {
      required: true,
      message: "请输入套餐价格",
      trigger: "blur",
    },
  ],
  comboType: [
    {
      required: true,
      message: "请选择套餐类型",
      trigger: "change",
    },
  ],
  day: [
    {
      required: true,
      message: "请输入预售周期",
      trigger: "blur",
    },
  ],
  date: [
    {
      required: true,
      message: "请选择可用日期",
      trigger: "change",
    },
  ],
  model: [
    {
      required: true,
      message: "请选择销售模式",
      trigger: "change",
    },
  ],
  cycle: [
    {
      required: true,
      message: "请选择上架周期",
      trigger: "change",
    },
  ],
  refundRule: [
    {
      required: true,
      message: "请选择退款规则",
      trigger: "change",
    },
  ],
});
/** 弹出 新增/编辑 弹框 */
const showAddDialog = () => {
  isEdit.value = false;
  // 表单验证重置
  if (addForm.value) {
    addForm.value.resetFields();
  }
  ruleForm.village = ""; // 项目
  ruleForm.hotel = ""; // 酒店
  ruleForm.name = ""; // 套餐名称
  ruleForm.type = ""; // 关联房型
  ruleForm.price = ""; // 套餐价格
  ruleForm.comboType = ""; // 套餐类型
  ruleForm.day = ""; // 预售周期
  ruleForm.date = ""; // 可用日期
  ruleForm.model = ""; // 销售模式
  ruleForm.cycle = ""; // 上架周期
  ruleForm.refundRule = ""; // 退款规则
  hotelOptions.value = [];
  roomTypeOptions.value = [];
  ruleVal.value = "";
  addDialog.value.show();
};
/** 退费规则绑定值 */
const ruleVal = ref("");
/** 退费规则列表 */
const ruleList = ref([]);
/** 获取退费规则数据 */
const getRefundRules = () => {
  if (ruleForm.hotel) {
    let data = {
      type: ruleVal.value,
      hid: ruleForm.hotel,
    };
    BasicApi.getRefundRules(data).then((res) => {
      if (res.Code === 200) {
        ruleList.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取退费规则数据失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("请选择酒店");
  }
};

/** 新增/编辑提交 */
const confirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        hp_name: ruleForm.name,
        hp_price: ruleForm.price,
        hp_refund_rule: ruleForm.refundRule,
      };
      if (ruleForm.comboType == 1) {
        data.hp_pre_sale_cycle = ruleForm.day;
      } else {
        data.hp_sale_type = ruleForm.model;
        data.hp_launch_start_time = ruleForm.cycle[0] ? ruleForm.cycle[0] : "";
        data.hp_launch_end_time = ruleForm.cycle[1] ? ruleForm.cycle[1] : "";
        data.hp_available_start_time = ruleForm.date[0] ? ruleForm.date[0] : "";
        data.hp_available_end_time = ruleForm.date[1] ? ruleForm.date[1] : "";
      }
      let url = "addHotelPackage";
      if (isEdit.value) {
        url = "editHotelPackage";
        data.hp_id = currentRow.value.hp_id;
      } else {
        data.hp_pid = ruleForm.village;
        data.hp_hid = ruleForm.hotel;
        data.hp_hrt_id = ruleForm.type;
        data.hp_type = ruleForm.comboType;
      }
      HotelApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        const text = isEdit.value ? "修改" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`套餐${text}成功！`);
          addDialog.value.close();
          // 重新获取套餐列表数据
          comboTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `套餐${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 套餐发布弹框 */
const issueDialog = ref(null);
/** 发布周期 */
const issueDate = ref([]);
/** 确认发布 */
const issueConfirm = () => {
  if (issueDate.value[0] && issueDate.value[1]) {
    issueDialog.value.isLoading = true;
    let data = {
      hp_id: currentRow.value.hp_id,
      start_time: issueDate.value[0],
      end_time: issueDate.value[1],
    };
    HotelApi.publishHotelPackage(data).then((res) => {
      issueDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success("套餐发布成功！");
        issueDialog.value.close();
        // 重新获取套餐列表数据
        comboTable.value.tableLoad();
      } else {
        let msg = res.Message ? res.Message : "套餐发布失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("请选择发布周期！");
  }
};

onMounted(() => {
  getProjectData();
  comboTable.value.tableLoad();
});
</script>

<style lang="scss">
.combo {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 30px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
              .el-date-editor {
                width: 100%;
                .el-range-input {
                  background-color: transparent;
                }
              }
            }
          }
          .el-form-item.radios {
            width: 100%;
            .el-radio-group {
              width: 100%;
              .el-radio {
                display: flex;
                white-space: pre-wrap;
                .el-radio__input {
                  .el-radio__inner {
                    width: 16px;
                    height: 16px;
                  }
                }
                .el-radio__label {
                  flex: 1;
                  line-height: 20px;
                }
              }
            }
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
          .filter-wp {
            width: 100%;
            padding: 20px 15px 0;
            > .el-select {
              margin-right: 15px;
              width: 192px;
            }
          }
        }
      }
    }
  }
  .issue-dialog {
    .el-dialog {
      min-width: 500px;
      .el-dialog__body {
        padding: 25px 25px 50px;
        .name {
          color: var(--text-color);
          margin-bottom: 10px;
        }
        .el-date-editor {
          width: 60%;
          .el-range-input {
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>
